
jQuery( document ).ready(function() {

    var base_url = window.location.origin;
    var page_id= jQuery("#single_page").attr("value");

    var content = '<b>Flush Cache</b><br><br>' ;
    content += 'Your site keeps cache to improve performance. <br>';
    content += 'Flushing the cache will temporarily harm your site\'s performance.<br><br>';
    content += '<b>Flush Cache anyway?</b><br><br>';
    content +=  '<a type="button" class="button" id="cancel_btn">Cancel</a>';
    content +=  '<a class="button" id="flush_entire" href="' + base_url + '/wp-admin/admin.php?page=wcms_flush_entire_site_bar">Flush Entire Site</a><br><br>' ;
    content += '<b>More Information</b><br>';
    content += 'Caching is basically a method to speed up browsing experience by temporarily storing web pages (or parts of the pages) on the server.<br>';
    content += 'You, as an admin, are not seeing the cached version. To view the site in its cached version, open the site in a new incognito window.<br><br>';
    content += '<br><b>When should you flush cache?</b><br>';
    content += 'As a rule of thumb, you don\'t need to flush the cache at all. It is flushed automatically <br>';
    content += '(a) whenever you save a page or a widget<br>';
    content += '(b) after a set interval.<br><br>';
    content += '<b>To maximize your site\'s performance, follow these few rules:</b><br>';
    content += '<li >Don\'t flush cache at all if you don\'t have to. In most cases, the page\'s cache is flushed ';
    content += 'automatically when you save a widget/page or after a set time interval.</li>';
    content += '<li>If you have to flush the cache, flush page assets only first: Swift Performance=>Clear Page Cache. Doing so will temporarily slow down page speed on a single page instead of on all pages.</li>';


    var newElement = '<div id="myModal-cache"></div> <div id="modal-content-cache" class="modal-content-cache">' + content + '</div>';

    if(jQuery('#wpadminbar').length){
        jQuery("body").append(newElement);
    }


    jQuery('#wp-admin-bar-wcms_flush_entire_site_bar').on('click', function () {
        var overlay = document.getElementById("myModal-cache");
        var popup = document.getElementById("modal-content-cache");

        overlay.style.display = "block";
        popup.style.display = "block";

    });

    jQuery('#cancel_btn').on('click', function () {
        var overlay = document.getElementById("myModal-cache");
        var popup = document.getElementById("modal-content-cache");

        overlay.style.display = "none";
        popup.style.display = "none";
    });
});






